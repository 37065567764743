import React, { useState } from 'react'
import Link from 'next/link'
import Engraving from '@components/product/Engraving'
import cartHandler from '@components/services/cart'
import { useUI } from '@components/ui/context'
import {
  GENERAL_ENGRAVING,
  GENERAL_QUICK_VIEW,
  ITEM_TYPE_ADDON,
  ADDED_TO_WISH,
  IMG_PLACEHOLDER,
  GENERAL_ADD_TO_BAG,
  PROD_ADDED_SUCCESSFULLY,
  INSUFFICIENT_STOCK,
  PROD_ADDED,
  MOVED_TO_BAG,
  CART_TEXT,
} from '@components/utils/textVariables'
import { groupBy, round, sortBy } from '@framework/utils/lodash'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import SwiperCore, { FreeMode, Navigation } from 'swiper'
import {
  EmptyGuid,
  NEXT_GET_WISHLIST,
  NEXT_REMOVE_WISHLIST,
  NEXT_CREATE_WISHLIST,
  AppsFlyerParams,
  AppsFlyerKeys,
  FacebookEvents,
} from '@components/utils/constants'
import axios from '@services/axiosInterceptorInstance'
import {
  matchStrings,
  priceFormat,
  getWordBreakLimitHandler,
} from '@framework/utils/parse-util'
import {
  getCurrentPage,
  openNewCartPage,
  isCartAssociated,
  removePrecedingSlash,
  getCategories,
  getCommonAppsFlyerValue,
  openLoginView,
  handleBrokenImageError,
  getBrowserIdForFBConversion,
  getIpAddressForFBConversion,
  getGenderHash,
  getDateOfBirthHash,
  getColor,
  getColorAndSize,
  eventIdForFB,
  removeSucceedingSlug,
} from '@framework/utils/app-util'
import cn from 'classnames'
import { isMobileOnly } from 'react-device-detect'
import { useRouter } from 'next/router'
import IntersectionObserverComponent, {
  IItem,
} from '@components/utils/IntersectionObserverComponent'
import useSection from 'hooks/useSection'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'
import { facebookEventHandler } from 'hooks/facebook-conversion'
import { getSHA256Hash } from '@framework/utils/cipher'
import { IProduct } from '@framework/api/operations/helpers/get-all-products.model'
import dynamic from 'next/dynamic'
import { useGoogleAnalyticsEvent } from 'hooks/useGAEvent'
import { getSessionItem } from '@components/utils/sessionStorage'
import { Tab } from '@headlessui/react'
import { CartIcon } from '@components/icons'
import { FREQUENTLY_BOUGHT_TOGETHER_VIEW_ALL_BTN } from '@framework/utils/constants'

const QuickViewModal = dynamic(() => import('../QuickView/ProductQuickView'), {
  ssr: false,
})

declare const window: any
declare const AF: any

const colorKey = 'global.colour'
interface Attribute {
  fieldName?: string
  fieldCode?: string
  fieldValues?: []
}
SwiperCore.use([Navigation])

export default function RelatedProducts({
  relatedProducts,
  checkout_refrence,
  title,
  handleQuickAddToBag,
  deviceInfo,
  pageType,
  getBasketPromos,
  setLoader,
  showTopBtn,
  showBtmBtn,
}: any) {
  const {
    openCart,
    addToWishlist,
    openWishlist,
    setSidebarView,
    setAlert,
    closeSidebar,
    cartItems,
    setWishlist,
  } = useUI()
  const { basketId, setCartItems, user } = useUI()
  const { isOnlyMobile } = deviceInfo
  const { asPath } = useRouter()
  const { handleSection } = useSection()
  const [quickViewProduct, setQuickViewProduct] = useState<string | null>(null)
  const [isEngravingOpen, showEngravingModal] = useState(false)
  const [sectionTitle, setSectionTitle] = useState('')
  const { isMobile, isIPadorTablet } = deviceInfo
  const isSideCartOpen = asPath?.includes('#cartopen')
  const isGapRequired = isOnlyMobile || isSideCartOpen
  const trackMoEngageEvent = useMoEngageEvent()
  const router = useRouter()
  const trackGoogleAnalyticsEvent = useGoogleAnalyticsEvent()
  const isChannelStore = router?.query?.channelStore ? true : false
  const storedActiveTab = getSessionItem('activeReturnTab')
  const [tabValue, setTabValue] = useState(
    storedActiveTab ? storedActiveTab : 0
  )
  // set default sort by price low to high to keep data display order consistency

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
  }

  const handleTabChange = (index: any) => {
    console.log('::', index)
  }

  const sortedRelatedProducts = relatedProducts
    ? sortBy(relatedProducts, 'displayOrder')
    : relatedProducts

  let currentPage = getCurrentPage()
  const onProductQuickView = (product: IProduct | null, pid?: any) => {
    const colorName = product?.attributes?.filter(
      (x: any) => x?.key == 'global.colour'
    )

    setQuickViewProduct(product?.slug ?? null)
    if (product?.name) {
      let color = ''
      if (product?.variantGroupCode) {
        color = product.variantGroupCode.split('-')[1]
      }
      let deviceCheck = ''
      if (isMobile || isIPadorTablet) {
        deviceCheck = 'Mobile'
      } else {
        deviceCheck = 'Desktop'
      }
      recordGA4Event(window, GA_EVENT.QUICK_VIEW_CLICK, {
        ecommerce: {
          device: deviceCheck,
          items: {
            product_name: product?.name,
            item_brand: product?.brand,
            category2: getCategories(product).category2,
            category: getCategories(product).category,
            item_variant: product?.attributes.find(
              (x: any) => x?.key === 'product.colours'
            )?.value,
            color: product?.attributes.find(
              (x: any) => x?.key === 'product.colours'
            )?.value,
            quantity: 1,
            item_id: product?.sku,
            position: pid + 1,
            product_price: product?.price?.raw?.withTax,
            item_var_id: product?.stockCode,
            item_list_name: getCategories(product).category,
            current_page: currentPage,
            header: title,
          },
          loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        },
      })
      recordGA4Event(window, GA_EVENT.POPUP_VIEW, {
        product_name: product?.name,
        category: product?.classification?.mainCategoryName,
        page: currentPage,
        position: pid + 1,
        // color: product?.variantGroupCode,
        color: product?.attributes.find(
          (x: any) => x?.key === 'product.colours'
        )?.value,
        price: product?.price?.raw?.withTax,
        current_page: 'Cart',
        item_brand: product?.brand,
        product_price: product?.price?.raw?.withTax,
        quantity: 1,
        item_id: product?.sku,
        item_variant: product?.attributes.find(
          (x: any) => x?.key === 'product.colours'
        )?.value,
        category2: product?.classification?.category,
        item_var_id: product?.stockCode,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      })

      trackMoEngageEvent(
        MO_ENGAGE_EVENT.QUICK_VIEW,
        {
          URL: window?.location?.href,
          Product_name: product?.name,
          Product_category: product?.classification?.mainCategoryName,
          Product_color: product?.variantGroupCode,
          Price: product?.price?.raw?.withTax,
        },
        window
      )
    }
  }

  function viewProductDetail(product: any, pid: number, title?: string) {
    handleSection(title ?? '')

    if (checkout_refrence == true) {
      recordGA4Event(window, GA_EVENT.REFERRER_BANNERS, {
        cross_sell_category_position: 'Checkout',
        section_title: title,
        product_name: product?.name,
        product_clicked_position: pid + 1,
        current_page: 'Cart',
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      })
    }
    if (typeof window !== 'undefined') {
      trackMoEngageEvent(
        MO_ENGAGE_EVENT.PRODUCT_VIEW,
        {
          URL: window?.location?.href,
          Product_name: product?.name,
          Product_category: product?.classification?.mainCategoryName,
          Product_category_2: product?.classification?.category,
          Product_color: product?.variantGroupCode,
          Price: product?.price?.raw?.withTax,
          UTM: '',
        },
        window
      )

      trackMoEngageEvent(
        MO_ENGAGE_EVENT.REFERRER_BANNERS,
        {
          section_title: title,
          Product_name: product?.name,
          product_clicked_position: pid + 1,
          current_page: 'Cart',
        },
        window
      )
    }
  }

  const getlineItemSizeWithoutSlug = (product: any) => {
    if (isChannelStore && product?.size !== '' && product?.size !== undefined) {
      return product?.size
    } else if (isChannelStore && product?.customAttributes) {
      return product?.customAttributes
        ?.filter((vp: any) => vp?.key === 'clothing.size')[0]
        ?.fieldText?.toUpperCase()
        ?.replaceAll('-', '')
        ?.replaceAll('SIZE', '')
    } else {
      return product?.variantProducts
        ?.filter((vp: any) => vp?.stockCode === product?.stockCode)[0]
        ?.variantAttributes?.filter(
          (va: any) => va?.fieldCode === 'clothing.size'
        )[0]
        ?.fieldValue?.toUpperCase()
        ?.replaceAll('-', '')
        ?.replaceAll('SIZE', '')
    }
  }

  const addToCartGAEvent = async (productData: any) => {
    const eventId = eventIdForFB()
    trackGoogleAnalyticsEvent(
      GA_EVENT.ADD_TO_CART,
      {
        ecommerce: {
          items: [
            {
              item_name: productData?.name,
              item_brand: productData?.brand,
              item_category2: getCategories(productData).category2,
              item_category: getCategories(productData).category,
              item_variant: getColorAndSize(productData),
              color: getColor(productData),
              quantity: productData?.qty,
              item_id: productData?.sku,
              item_size: getlineItemSizeWithoutSlug(productData),
              price: productData?.price?.raw?.withTax,
              item_var_id: productData?.stockCode,
              item_list_name: getCategories(productData).category,
              index: 1,
            },
          ],
          cart_quantity: 1,
          total_value: productData?.price?.raw?.withTax,
          current_page: getCurrentPage(),
          section_title: title ?? 'Product Detail',
          event_id: eventId,
          loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        },
      },
      window
    )

    const mobileNumber = user?.mobile || user?.telephone
    let facebookeventData = {
      data: [
        {
          event_name: FacebookEvents.ADD_TO_CART,
          event_time: Math.floor(new Date().getTime() / 1000),
          event_id: eventId,
          action_source: 'website',
          user_data: {
            em: [user?.email ? await getSHA256Hash(user?.email) : ''],
            ph: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
            fn: user?.firstName ? await getSHA256Hash(user?.firstName) : null,
            ln: user?.lastName ? await getSHA256Hash(user?.lastName) : null,
            client_user_agent: window.navigator.userAgent,
            fbp: getBrowserIdForFBConversion(),
            client_ip_address: (await getIpAddressForFBConversion()) || null,
            external_id: mobileNumber
              ? await getSHA256Hash(mobileNumber)
              : null,
            db: await getDateOfBirthHash(user),
            ge: await getGenderHash(user?.gender),
            ct: null,
            zp: null,
            country: await getSHA256Hash('in'),
          },
          custom_data: {
            currency: 'INR',
            num_items: 1,
            content_name: getCurrentPage(),
            value: productData?.price?.raw?.withTax,
            content_category: getCategories(productData).category,
            content_ids: [productData?.stockCode],
            content_type: 'product',
            contents: [
              {
                id: productData?.recordId ?? productData?.productId,
                quantity: 1,
                item_price: productData?.price?.raw?.withTax,
                delivery_category: 'home_delivery',
              },
            ],
          },
        },
      ],
    }

    facebookEventHandler(facebookeventData)
  }

  const addToCart = (product: any) => {
    const asyncAddToCart = async () => {
      const item = await cartHandler().addToCart(
        {
          basketId: basketId,
          productId: product.recordId,
          qty: 1,
          // manualUnitPrice: product.price.raw.withTax,
          stockCode: product.stockCode,
          userId: user.userId,
          isAssociated: isCartAssociated(cartItems), //user.isAssociated,
        },
        'ADD',
        { product },
        (item: any) => {
          // setCartItems(item)
          // openCart()
        }
      )
      // setCartItems(item)
      if (
        item?.message &&
        !matchStrings(item?.message, PROD_ADDED_SUCCESSFULLY, true)
      ) {
        setAlert({ type: 'error', msg: INSUFFICIENT_STOCK })
      } else if (item?.id === EmptyGuid) {
        setAlert({ type: 'error', msg: INSUFFICIENT_STOCK })
      } else {
        setAlert({ type: 'success', msg: PROD_ADDED })
        addToCartGAEvent(product)
        setCartItems(item)
        //         openCart();
        openNewCartPage(openCart)
      }
    }
    asyncAddToCart()
  }
  const insertToLocalWishlist = (product: any) => {
    addToWishlist(product)
    setAlert({ type: 'success', msg: ADDED_TO_WISH })
    openWishlist()
  }
  const handleWishList = async (product: any) => {
    const accessToken = localStorage?.getItem('user')
    if (accessToken) {
      const createWishlist = async () => {
        try {
          const res = await axios.post(NEXT_CREATE_WISHLIST, {
            id: user?.userId,
            productId: product?.recordId,
            flag: true,
          })
          if (res?.data) {
            insertToLocalWishlist(product)
          }
        } catch (error) {
          console.error(':: error ', error)
        }
      }
      createWishlist()
    } else {
      closeSidebar()
      openLoginView(asPath)
      setSidebarView('LOGIN_VIEW')
      // insertToLocalWishlist()
    }
    let productAvailability = 'Yes'
    if (product?.currentStock > 0) {
      productAvailability = 'Yes'
    } else {
      productAvailability = 'No'
    }
    const eventId = eventIdForFB()
    recordGA4Event(window, GA_EVENT.ADD_TO_WISHLIST, {
      ecommerce: {
        items: [
          {
            item_name: product?.name,
            item_brand: product?.brand,
            item_category2: getCategories(product).category2,
            item_category: getCategories(product).category,
            color: product?.attributes.find(
              (x: any) => x?.key === 'product.colours'
            )?.value,
            item_variant: product?.attributes.find(
              (x: any) => x?.key === 'product.colours'
            )?.value,
            quantity: 1,
            item_id: product?.sku,
            price: product?.price?.raw?.withTax,
            item_var_id: product?.stockCode,
            item_list_name: getCategories(product).category,
            index: 1,
          },
        ],
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        header: 'Frequent Bought Together',
        current_page: currentPage,
        event_id: eventId,
        availability: productAvailability,
      },
    })

    const mobileNumber = user?.mobile || user?.telephone
    let facebookeventData: any = {
      data: [
        {
          event_name: FacebookEvents.ADD_TO_WISHLIST,
          event_time: Math.floor(new Date().getTime() / 1000),
          event_id: eventId,
          action_source: 'website',
          user_data: {
            em: [user?.email ? await getSHA256Hash(user?.email) : ''],
            ph: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
            fn: user?.firstName ? await getSHA256Hash(user?.firstName) : null,
            ln: user?.lastName ? await getSHA256Hash(user?.lastName) : null,
            client_user_agent: window.navigator.userAgent,
            fbp: getBrowserIdForFBConversion(),
            client_ip_address: (await getIpAddressForFBConversion()) || null,
            external_id: mobileNumber
              ? await getSHA256Hash(mobileNumber)
              : null,
            db: await getDateOfBirthHash(user),
            ge: await getGenderHash(user?.gender),
            ct: null,
            zp: null,
            country: await getSHA256Hash('in'),
          },
          custom_data: {
            currency: 'INR',
            content_name: getCurrentPage(),
            content_category: getCategories(product).category,
            content_ids: [product?.stockCode],
            content_type: 'product',
            contents: [
              {
                id: product?.recordId ?? product?.productId,
                quantity: 1,
                item_price: product?.price?.raw?.withTax,
                delivery_category: 'home_delivery',
              },
            ],
          },
        },
      ],
    }

    facebookEventHandler(facebookeventData)

    if (typeof window !== 'undefined') {
      trackMoEngageEvent(
        MO_ENGAGE_EVENT.ADD_TO_WISHLIST,
        {
          URL: window?.location?.href,
          Product_name: product?.name,
          Product_category: product?.classification?.mainCategoryName,
          Product_category_2: product?.classification?.category,
          Product_color: '',
          Price: product?.price?.raw?.withTax,
        },
        window
      )
    }
    if (typeof AF !== 'undefined') {
      const commonEventValue = getCommonAppsFlyerValue()
      AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: AppsFlyerKeys.AddToWishlist,
        eventRevenueCurrency: 'INR',
        eventValue: {
          [AppsFlyerParams.AFEventParam3]: currentPage,
          [AppsFlyerParams.AFEventParamContent]: product?.name,
          [AppsFlyerParams.AFEventParamContentId]: product?.stockCode,
          [AppsFlyerParams.AFEventParamPrice]:
            product?.price?.raw?.withTax + '',
          [AppsFlyerParams.AFEventParam5]:
            product?.classification?.mainCategoryName || '',
          [AppsFlyerParams.AFEventParam6]:
            product?.classification?.category || '',
          ...commonEventValue,
        },
      })
    }
  }

  const fetchItems = async () => {
    try {
      const response: any = await axios.post(NEXT_GET_WISHLIST, {
        id: user.userId,
        flag: true,
      })
      // setIsLoading(false)
      setWishlist(response.data)
    } catch (error) {
      console.error(':: error ', error)
      // setIsLoading(false)
    }
  }

  const handleRemoveFromWishlist = (product: any) => {
    const handleAsync = async () => {
      try {
        await axios.post(NEXT_REMOVE_WISHLIST, {
          id: user?.userId,
          productId: product?.recordId || product?.productId,
          flag: true,
        })
        fetchItems()
      } catch (error) {
        console.error(':: error ', error)
      }
    }
    let productAvailability = 'Yes'
    if (product?.currentStock > 0) {
      productAvailability = 'Yes'
    } else {
      productAvailability = 'No'
    }

    recordGA4Event(window, GA_EVENT.REMOVE_ITEM, {
      product_name: product?.name,
      availability: productAvailability,
      product_id: product?.sku,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
    handleAsync()
  }

  const handleAddToCart = (product: any) => {
    // setIsLoading(true)
    cartHandler()
      .addToCart(
        {
          basketId,
          productId: product.recordId,
          qty: 1,
          // manualUnitPrice: product.price.raw.withTax,
          stockCode: product.stockCode,
          userId: user.userId,
          isAssociated: isCartAssociated(cartItems), //user.isAssociated,
        },
        'ADD',
        { product }
      )
      .then((response: any) => {
        setCartItems(response)
        setAlert({ type: 'success', msg: MOVED_TO_BAG })
        handleRemoveFromWishlist(product)
      })
      .catch((err: any) => {
        console.error(':: error ', err)
      })
  }

  const groupLength = sortedRelatedProducts?.find(
    (x: any) => x.groupNameList
  )?.groupNameList
  const checkBasket = groupLength?.find(
    (x: any) => x.relatedTypeCode == 'BASKETGROUP'
  )?.relatedTypeCode

  //let relatedProductGroup = groupBy(relatedProducts, 'groupNameList[0].name')
  let relatedProductGroup: any
  if (groupLength?.length == 2) {
    relatedProductGroup = groupBy(
      sortedRelatedProducts,
      'groupNameList[1].name'
    )
  }
  relatedProductGroup = groupBy(sortedRelatedProducts, 'groupNameList[0].name')
  const [currentProductData, setCurrentProductData] = useState({
    image: sortedRelatedProducts?.image,
    link: sortedRelatedProducts?.slug,
  })

  const orderedRelatedProductGroup = Object.keys(relatedProductGroup)
    .sort()
    .reduce((obj: any, key) => {
      obj[key] = relatedProductGroup[key]
      return obj
    }, {})

  const getWordBreakLimit = getWordBreakLimitHandler(isMobileOnly, deviceInfo)

  const relatedProductsTabs = ['FREE-EARPODES']
  return (
    <>
      <section aria-labelledby="related-heading" className="px-0 pr-0 sm:px-0">
        {Object.entries(orderedRelatedProductGroup)?.map(
          ([key, values]: any, iid: number) => {
            const title =
              key == 'You May Also Like'
                ? 'Frequent Bought Together'
                : key == 'undefined'
                ? 'Frequent Bought Together'
                : key == 'Upgrade'
                ? 'Quick Add'
                : key == 'Basket Group'
                ? 'Frequent Bought Together'
                : key == 'Quickly Add'
                ? 'Quick Add'
                : key ?? 'N/A'
            const items: IItem[] = values.map((product: any, index: number) => {
              let color = ''
              if (product?.variantGroupCode) {
                color = product.variantGroupCode.split('-')[1]
              }
              return {
                item_name: product.name,
                item_id: product.sku,
                price: product.price.raw.withTax,
                item_brand: product.brand,
                item_category: getCategories(product).category,
                item_category2: getCategories(product).category2,
                item_variant: color,
                color,
                item_list_name: getCategories(product).category,
                // item_list_id: number;
                index,
                item_var_id: product.stockCode,
              }
            })
            return (
              key != 'You May Also Like' && (
                <>
                  <IntersectionObserverComponent
                    title={title}
                    eventName={GA_EVENT.VIEW_ITEM_LIST}
                    items={items}
                  >
                    <div
                      key={iid}
                      className={`${
                        isGapRequired
                          ? 'px-4 md:px-8'
                          : pageType === CART_TEXT
                          ? 'px-4 sm:px-8 md:px-0 lg:px-0'
                          : 'px-4 sm:px-8 md:px-0 lg:px-0'
                      }`}
                    >
                      <div className="flex flex-col mb-3">
                        <h3 className="font-bold capitalize text-20 text-dark-brown">
                          {title}
                        </h3>
                      </div>
                    </div>
                    {/* tabs */}
                    {relatedProductsTabs?.length > 1 && (
                      <Tab.Group
                        as="div"
                        selectedIndex={tabValue}
                        onChange={(index: any) => {
                          console.log('::', index)
                        }}
                      >
                        <div className="mb-[25px] px-4 md:px-8">
                          <Tab.List className="w-full flex gap-6 border-b border-gray-200">
                            {relatedProductsTabs?.map((value: string) => {
                              return (
                                <>
                                  <Tab
                                    className={({ selected }) =>
                                      classNames(
                                        'pb-3 text-sm whitespace-nowrap ',
                                        selected
                                          ? 'border-b-2 border-solid border-black dark:text-black text-gray-dark font-medium'
                                          : 'opacity_056 font-normal text-[#251000]'
                                      )
                                    }
                                    style={{
                                      outline: 'none',
                                    }}
                                  >
                                    {value}
                                  </Tab>
                                </>
                              )
                            })}
                          </Tab.List>
                        </div>
                      </Tab.Group>
                    )}
                    <div className="mb-8 default-sm mobile-slider-no-arrow m-hide-navigation sm:mb-8">
                      <Swiper
                        slidesPerView={isGapRequired ? 2.3 : 4}
                        // slidesPerView={'auto'}
                        spaceBetween={6}
                        navigation={true}
                        loop={false}
                        freeMode={true}
                        modules={[FreeMode]}
                        breakpoints={{
                          320: {
                            slidesPerView: isGapRequired ? 2.3 : 3,
                            spaceBetween: 4,
                            slidesOffsetAfter: isGapRequired ? 0 : 0,
                            slidesOffsetBefore: isGapRequired ? 16 : 0,
                          },
                          640: {
                            slidesPerView: isGapRequired ? 2.3 : 3,
                            spaceBetween: 4,
                            slidesOffsetAfter: isGapRequired ? 30 : 0,
                            slidesOffsetBefore: isGapRequired ? 16 : 0,
                          },
                          768: {
                            slidesPerView: isGapRequired ? 2.3 : 4,
                            spaceBetween: 8,
                            slidesOffsetAfter: isGapRequired ? 0 : 0,
                            slidesOffsetBefore: isGapRequired ? 32 : 0,
                          },
                          1024: {
                            slidesPerView: isGapRequired ? 2.3 : 4.1,
                            spaceBetween: 8,
                            slidesOffsetAfter: isGapRequired ? 0 : 0,
                            slidesOffsetBefore: isGapRequired ? 32 : 0,
                          },
                        }}
                        slidesOffsetAfter={isGapRequired ? 30 : 0}
                        slidesOffsetBefore={isGapRequired ? 32 : 0}
                        className="mySwiper"
                      >
                        {values?.map((product: any, pid: number) => {
                          const isEngravingAvailable =
                            product.stockCode === ITEM_TYPE_ADDON
                          const saving =
                            product?.price?.maxPrice - product?.price?.minPrice
                          const discount = round(
                            (saving / product?.price?.maxPrice) * 100,
                            0
                          )
                          const handleVariableProduct = (
                            attr: any,
                            type: string = 'enter'
                          ) => {
                            if (type === 'enter') {
                              const variatedProduct =
                                product?.variantProductsMinimal?.find(
                                  (item: any) =>
                                    item.variantAttributes.find(
                                      (variant: any) =>
                                        variant?.fieldValue === attr?.fieldValue
                                    )
                                )
                              if (variatedProduct) {
                                setCurrentProductData({
                                  image: variatedProduct?.image,
                                  link: variatedProduct?.slug,
                                })
                              }
                            } else {
                              setCurrentProductData({
                                image: product?.image,
                                link: product?.slug,
                              })
                            }
                          }
                          const productWithColors =
                            product?.variantProductsAttributeMinimal &&
                            product?.variantProductsAttributeMinimal.find(
                              (item: Attribute) => item?.fieldCode === colorKey
                            )
                          const hasColorVariation =
                            productWithColors &&
                            productWithColors?.fieldValues?.length >= 1
                          return (
                            <>
                              <SwiperSlide key={pid}>
                                <div className="w-full text-left">
                                  <div className="relative">
                                    <div className="imae-container">
                                      <Link
                                        legacyBehavior
                                        href={`/${removeSucceedingSlug(product?.slug)}`}
                                        as={`/${removePrecedingSlash(product?.slug)}`}
                                        passHref
                                      >
                                        <a
                                          onClick={() =>
                                            viewProductDetail(
                                              product,
                                              pid,
                                              title
                                            )
                                          }
                                        >
                                          <img
                                            src={
                                              product?.image || IMG_PLACEHOLDER
                                            }
                                            alt={product?.name}
                                            width={284}
                                            height={505}
                                            className="object-cover object-center w-full h-full radius-xs sm:h-full"
                                            onError={(e: any) =>
                                              handleBrokenImageError(e)
                                            }
                                          />
                                        </a>
                                      </Link>
                                    </div>
                                    {/* <div className="grid grid-cols-12 px-2 sm:grid-cols-12 sm:gap-x-2">
                                    <div className="col-span-8 text-left sm:col-span-8 hf-28px">
                                      <h5 className="w-11/12 py-1 mt-1 overflow-hidden font-normal truncate text-10 text-brown-light dark:text-brown-light sm:text-10 sm:group-hover:hidden fh-24">
                                        {product?.classification?.category}
                                      </h5>
                                    </div>
                                    {product?.rating > 0 && (
                                      <div className="col-span-4 text-right sm:col-span-4">
                                        <i className="sprite-icon sprite-star"></i>
                                        <span className="ml-2 font-semibold text-12 text-primary dark:text-primary">
                                          {product?.rating}
                                        </span>
                                      </div>
                                    )}
                                  </div> */}
                                    <div className="relative px-0 text-left">
                                      {/* <h3 className="px-0 py-1 font-normal leading-4 text-12 text-primary dark:text-primary sm:text-12 sm:group-hover:hidden fh-48 ipd-visible"> */}
                                      <h3
                                        className="px-0 py-1 font-medium text-12 text-primary dark:text-primary sm:text-12 sm:group-hover:hidden fh-40 ipd-visible text-truncate-two-lines"
                                        title={product?.name}
                                      >
                                        <Link
                                          legacyBehavior
                                          href={`/${removePrecedingSlash(
                                            product?.slug
                                          )}`}
                                          passHref
                                        >
                                          <a
                                            href={`/${removePrecedingSlash(
                                              product?.slug
                                            )}`}
                                            onClick={() =>
                                              viewProductDetail(
                                                product,
                                                pid,
                                                title
                                              )
                                            }
                                            className="text-truncate-two-lines"
                                          >
                                            {product?.name}
                                            {/* {truncate(
                                              product?.name,
                                              getWordBreakLimit
                                            )} */}
                                          </a>
                                        </Link>
                                      </h3>
                                    </div>

                                    <div className="flex px-0">
                                      <p className="mt-2 mb-2 font-medium text-left text-primary xs-text-10 text-12 sm:mt-1 sm:group-hover:hidden sm:mb-0">
                                        {priceFormat(product?.price?.minPrice)}
                                        {discount > 0 ? (
                                          <>
                                            <span className="px-1 font-normal text-gray-400 line-through sm:px-2 xs-text-10 text-12">
                                              {priceFormat(
                                                product?.price?.maxPrice
                                              )}
                                            </span>
                                            <span className="inline-block xs-text-10 text-12 text-green">
                                              {discount}% off
                                            </span>
                                          </>
                                        ) : null}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex justify-around px-1 mt-2">
                                    {/* {wishListItems
                                    .map((wi: any) => wi?.recordId)
                                    .includes(product?.recordId) ? (
                                    <div className="relative flex items-center justify-center px-3 py-2 -mr-1 text-sm font-medium bg-white border border-gray-300 sm:px-6 btn-basic-property cart-btn text-dark-brown add-wishlist">
                                      <i className="sprite-icon sprite-o-whislist"></i>
                                    </div>
                                  ) : (
                                    <button
                                      className="relative flex items-center justify-center px-3 py-2 -mr-1 text-sm font-medium bg-white border border-gray-300 sm:px-6 btn-basic-property cart-btn text-dark-brown hover:bg-orange-400"
                                      onClick={() => handleWishList(product)}
                                    >
                                      <i className="sprite-icon sprite-whislist"></i>
                                      <span className="text-sm font-medium text-gray-700 hover:text-red-800"></span>
                                      <span className="sr-only">f</span>
                                    </button>
                                  )} */}
                                    {showBtmBtn && (
                                      <button
                                        onClick={() => {
                                          if (
                                            key === 'Upgrade' ||
                                            key === 'Quickly Add'
                                          ) {
                                            // handleQuickAddToBag(product, key);
                                            handleQuickAddToBag(
                                              product,
                                              'Upgrade'
                                            )
                                          } else {
                                            onProductQuickView(product, pid)
                                          }
                                          setSectionTitle(title)
                                        }}
                                        type="button"
                                        className={cn(
                                          'relative flex items-center justify-center w-full px-1 py-1 text-xs font-semibold sm:text-sm btn-basic-property-sm cart-btn',
                                          {
                                            'border-black bg-black hover:bg-gray-900 text-white':
                                              key === 'Upgrade' ||
                                              key === 'Quickly Add',
                                            'text-black bg-white border border-gray-300':
                                              key !== 'Upgrade' &&
                                              key !== 'Quickly Add',
                                          }
                                        )}
                                      >
                                        {key === 'Upgrade' ||
                                        key === 'Quickly Add'
                                          ? GENERAL_ADD_TO_BAG
                                          : GENERAL_QUICK_VIEW}
                                      </button>
                                    )}
                                    <Link
                                      legacyBehavior
                                      href={`/${removePrecedingSlash(
                                        product?.slug
                                      )}`}
                                      passHref
                                    >
                                      <span className="sr-only">
                                        , {product.name}
                                      </span>
                                    </Link>
                                    {isEngravingAvailable && (
                                      <>
                                        <button
                                          className="relative flex items-center justify-center w-full py-2 mt-2 text-sm font-semibold text-white bg-gray-400 border border-transparent rounded-md btn-basic-property cart-btn hover:bg-gray-500"
                                          onClick={() =>
                                            showEngravingModal(true)
                                          }
                                        >
                                          <span className="font-bold">
                                            {GENERAL_ENGRAVING}
                                          </span>
                                        </button>
                                        <Engraving
                                          show={isEngravingOpen}
                                          submitForm={() => addToCart(product)}
                                          onClose={() =>
                                            showEngravingModal(false)
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                                {showTopBtn && (
                                  <div className="absolute top-0 right-0 flex justify-center items-center gap-[2px] px-2 py-[6px] bg-white rounded-sm border ">
                                    <CartIcon />
                                    <span className="flex items-center justify-center w-[10px] h-[14px] text-[15px] font-normal">
                                      +
                                    </span>
                                  </div>
                                )}
                              </SwiperSlide>
                            </>
                          )
                        })}
                        {FREQUENTLY_BOUGHT_TOGETHER_VIEW_ALL_BTN && (
                          <>
                            <SwiperSlide>
                              <div className="w-full flex text-left justify-center items-center h-[300px]">
                                <button className="border  border-[#25100029]  rounded-[36px] py-[12px] px-[18px] gap-2">
                                  <span className="text-[#EB5B25] text-[16px] font-medium">
                                    View All →
                                  </span>
                                </button>
                              </div>
                            </SwiperSlide>
                          </>
                        )}
                      </Swiper>
                    </div>
                  </IntersectionObserverComponent>
                </>
              )
            )
          }
        )}

        {/* Add from wishlist section */}
        {/* Commenting the code till further notice */}
        {/* {(pageType === CART_TEXT && !wishlistLoader) && (
          <>
            {((!isGuestUser && user?.userId) && wishListItems?.length > 0) && (
              <>
                <div>
                  <div className="flex flex-col mb-3">
                    <h3 className={`${isGapRequired ? 'px-4 md:px-8' : pageType === CART_TEXT ? 'px-4 sm:px-8 md:px-0 lg:px-0' : 'px-4 sm:px-8 md:px-0 lg:px-0'} font-bold capitalize text-20 text-dark-brown`}>
                      {ADD_FROM_WISHLIST}
                    </h3>
                  </div>
                </div>
                <div className="mb-8 default-sm mobile-slider-no-arrow m-hide-navigation sm:mb-8">
                  <Swiper
                    slidesPerView={isGapRequired ? 2.3 : 4}
                    spaceBetween={6}
                    navigation={true}
                    loop={false}
                    freeMode={true}
                    modules={[FreeMode]}
                    breakpoints={{
                      320: {
                        slidesPerView: isGapRequired ? 2.3 : 3,
                        spaceBetween: 4,
                        slidesOffsetAfter: isGapRequired ? 0 : 0,
                        slidesOffsetBefore: isGapRequired ? 16 : 0,
                      },
                      640: {
                        slidesPerView: isGapRequired ? 2.3 : 3,
                        spaceBetween: 4,
                        slidesOffsetAfter: isGapRequired ? 30 : 0,
                        slidesOffsetBefore: isGapRequired ? 16 : 0,
                      },
                      768: {
                        slidesPerView: isGapRequired ? 2.3 : 4,
                        spaceBetween: 8,
                        slidesOffsetAfter: isGapRequired ? 0 : 0,
                        slidesOffsetBefore: isGapRequired ? 32 : 0,
                      },
                      1024: {
                        slidesPerView: isGapRequired ? 2.3 : 4.1,
                        spaceBetween: 8,
                        slidesOffsetAfter: isGapRequired ? 0 : 0,
                        slidesOffsetBefore: isGapRequired ? 32 : 0,
                      },
                    }}
                    slidesOffsetAfter={isGapRequired ? 30 : 0}
                    slidesOffsetBefore={isGapRequired ? 32 : 0}
                    className="mySwiper"
                  >
                    {wishListItems?.map((product: any, pid: number) => {
                      const isEngravingAvailable =
                        product.stockCode === ITEM_TYPE_ADDON

                      const saving = product?.listPrice?.raw?.withTax - product?.price?.raw?.withTax;
                      const discount = round((saving / product?.listPrice?.raw?.withTax) * 100, 0);

                      return (
                        <>
                          <SwiperSlide key={pid}>
                            <div className="grid w-full grid-cols-1 text-left">
                              <div className="relative">
                                <div className="imae-container">
                                  <Link legacyBehavior href={`/${removePrecedingSlash(product?.slug)}`} passHref>
                                    <a
                                      onClick={() =>
                                        viewProductDetail(product, pid)
                                      }
                                    >
                                      <img
                                    
                                        src={product?.image || IMG_PLACEHOLDER}
                                        alt={product?.name}
                                        priority
                                        
                                        width={284}
                                        height={505}
                                        className="object-cover object-center w-full h-full radius-xs sm:h-full"
                                      />
                                    </a>
                                  </Link>
                                </div>
                                <div className="relative px-0 text-left">
                                  <h3 className="px-0 py-1 font-medium text-12 text-primary dark:text-primary sm:text-12 sm:group-hover:hidden fh-40 ipd-visible" title={product?.name}>
                                    <Link legacyBehavior href={`/${removePrecedingSlash(product?.slug)}`} passHref>
                                      <a
                                        href={`/${removePrecedingSlash(product?.slug)}`}
                                        onClick={() =>
                                          viewProductDetail(product, pid)
                                        }
                                      >
                                        {truncate(
                                          product?.name,
                                          getWordBreakLimit
                                        )}
                                      </a>
                                    </Link>
                                  </h3>
                                </div>

                                <div className="flex px-0">
                                  <p className="mt-1 mb-2 font-medium text-12 text-primary sm:mt-2 min-h-40 sm:mb-0">
                                    {priceFormat(product?.price?.raw?.withTax)}
                                    {product?.listPrice?.raw?.withTax > product?.price?.raw?.withTax ? (
                                      <>
                                        <span className="px-2 font-normal text-gray-500 line-through text-12">
                                          {priceFormat(product?.listPrice?.raw?.withTax)}
                                        </span>
                                        <span className='font-normal text-12 text-emerald-500'>{discount}% off</span>
                                      </>
                                    ) : null}
                                  </p>
                                </div>
                              </div>
                              <div className="flex justify-around px-1 mt-2">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleAddToCart(product)
                                  }}
                                  className="flex items-center justify-center w-full p-3 text-xs font-semibold text-black border"
                                >
                                  <span className='mr-2'>
                                    <i className="sprite-icon sprite-cart" />
                                  </span>
                                  {GENERAL_MOVE_TO_BAG}
                                </button>
                                {isEngravingAvailable && (
                                  <>
                                    <button
                                      className="relative flex items-center justify-center w-full py-2 mt-2 text-sm font-semibold text-white bg-gray-400 border border-transparent rounded-md btn-basic-property cart-btn hover:bg-gray-500"
                                      onClick={() => showEngravingModal(true)}
                                    >
                                      <span className="font-bold">
                                        {GENERAL_ENGRAVING}
                                      </span>
                                    </button>
                                    <Engraving
                                      show={isEngravingOpen}
                                      submitForm={() => addToCart(product)}
                                      onClose={() => showEngravingModal(false)}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </SwiperSlide>
                        </>
                      )
                    })}
                  </Swiper>
                </div>
              </>
            )}
          </>
        )} */}
      </section>
      {quickViewProduct && (
        <QuickViewModal
          quickViewProductSlug={quickViewProduct}
          setQuickview={(payload: string | null) => {
            onProductQuickView(null)
          }}
          collectionName={sectionTitle}
          isFromCart={true}
          deviceInfo={deviceInfo}
          getBasketPromos={getBasketPromos}
          title={title}
          page={'Cart Quick View'}
        />
      )}
    </>
  )
}
