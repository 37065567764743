import React, { useCallback, useEffect, useState } from 'react'

import { useUI } from '@components/ui'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import { ProductService } from 'services/product/index.service'
import { IPromotion } from './helpers/pomotions.model'
import { priceFormat } from '@framework/utils/parse-util'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import NewCouponIcon from '@components/icons/NewCouponIcon'
import {
  EmptyGuid,
  NEXT_PUBLIC_IMAGE_BASE_URL,
} from '@components/utils/constants'
import WhiteCartIcon from '@components/icons/WhiteCartIcon'
import { useGoogleAnalyticsEvent } from 'hooks/useGAEvent'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { getCurrentPage } from '@framework/utils/app-util'
import IntersectionObserverComponent from '@components/utils/IntersectionObserverComponent'

const PromotionDetailPopup = dynamic(() => import('./PromotionDetailPopup'), {
  ssr: false,
})

declare const AF: any

SwiperCore.use([Pagination])

interface IProps {
  elementTagId?: string
  elementTagClass?: string
  firstProductRecordId?: string
  deviceInfo: any
  previousPath: string
}

interface IParsedAdditionalInfo9 {
  cta: string
  img: string
  maxval: string
  page: string
}

export const parseAdditionalInfo9 = (
  jsonString?: string
): IParsedAdditionalInfo9 => {
  if (!jsonString || jsonString === '') {
    return {
      cta: '',
      img: '',
      maxval: '',
      page: '',
    }
  }
  const parsedData = JSON.parse(jsonString)
  return {
    cta: parsedData?.cta ?? '',
    img: parsedData?.img
      ? `${NEXT_PUBLIC_IMAGE_BASE_URL}${parsedData?.img}`
      : '',
    maxval: parsedData?.maxval ?? '',
    page: parsedData?.page ?? '',
  }
}

const ProductListStickyBar = ({
  firstProductRecordId,
  elementTagId,
  elementTagClass,
  deviceInfo,
  previousPath,
}: IProps) => {
  const router = useRouter()
  const [totalDiscount, setTotalDiscount] = useState(0)
  const [availablePromotions, setAvailablePromotions] = useState<IPromotion[]>(
    []
  )
  const [applicablePromotions, setApplicablePromotions] = useState<
    IPromotion[]
  >([])
  const [promotionData, setPromotionData] = useState<IPromotion | null>(null)
  const [itemsInCart, setItemsInCart] = useState<Number>(0)
  const [isOpenDetailPopup, setOpenDetailPopup] = useState<Boolean>(false)
  const { cartItems } = useUI()
  const trackGoogleAnalyticsEvent = useGoogleAnalyticsEvent()
  const { user } = useUI()

  useEffect(() => {
    let totalPriceSaving = 0
    let updateQtySaving = 0
    cartItems?.lineItems?.forEach((product: any) => {
      if (product?.listPrice?.raw?.withTax > product?.price?.raw?.withTax) {
        const saving =
          product?.listPrice?.raw?.withTax - product?.price?.raw?.withTax
        updateQtySaving = saving * product?.qty
        totalPriceSaving = totalPriceSaving + updateQtySaving
      }
    })

    const itemsInBag = () => {
      return cartItems?.lineItems
        ?.map((item: any) => item.qty)
        .reduce((sum: number, current: number) => sum + current, 0)
    }

    setItemsInCart(itemsInBag())

    setTotalDiscount(totalPriceSaving + cartItems?.discount?.raw?.withTax)
  }, [cartItems])

  const enableStickyProductInfo = (tagId: string, isClass: boolean) => {
    // Cache selectors for faster performance.
    const mainMenuBar: any = document.querySelector('.min-product-info-plp'),
      mainMenuBarAnchor: any = isClass
        ? document.getElementsByClassName(`${tagId}`)
        : document.getElementById(`${tagId}`)

    // Run this on scroll events.
    document.addEventListener('scroll', () => {
      var window_top = window?.scrollY
      var div_top = mainMenuBarAnchor?.offsetTop
      if (window_top > div_top) {
        // Make the div sticky.
        mainMenuBar.classList.add('active')
      }
    })
  }

  useEffect(() => {
    if (
      elementTagId &&
      (applicablePromotions.length || availablePromotions.length)
    )
      enableStickyProductInfo(elementTagId, false)

    return () => {
      document.removeEventListener('scroll', () => {})
    }
  }, [elementTagId, applicablePromotions.length, availablePromotions.length])

  useEffect(() => {
    if (
      elementTagClass &&
      (applicablePromotions.length || availablePromotions.length)
    )
      enableStickyProductInfo(elementTagClass, true)

    return () => {
      document.removeEventListener('scroll', () => {})
    }
  }, [elementTagClass, applicablePromotions.length, availablePromotions.length])

  const getBasketPromotions = useCallback(
    async (basketId: string) => {
      try {
        const res = await ProductService.getBasketPromotions(
          basketId,
          firstProductRecordId ?? undefined
        )
        if (res.applicablePromotions.length) {
          setApplicablePromotions(res.applicablePromotions)
        }
        if (res.availablePromotions.length) {
          setAvailablePromotions(res.availablePromotions)
        }
      } catch (err: any) {
        throw new Error(err)
      }
    },
    [firstProductRecordId]
  )

  useEffect(() => {
    if (cartItems?.id && cartItems?.lineItems?.length) {
      getBasketPromotions(cartItems?.id)
    }
  }, [cartItems?.id, cartItems?.lineItems?.length, getBasketPromotions])

  if (
    (!cartItems?.id ||
      cartItems?.id === '' ||
      !cartItems?.lineItems?.length ||
      cartItems?.lineItems?.length === 0) &&
    (!applicablePromotions.length || !availablePromotions.length)
  ) {
    return null
  }

  const bottomNudgeClickEvent = (datum: any) => {
    trackGoogleAnalyticsEvent(
      GA_EVENT.BOTTOM_NUDGE_CLICK,
      {
        promotion_name: datum?.name,
        promotion_promocode: datum?.code,
        current_page: getCurrentPage(),
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      },
      window
    )
  }

  return (
    <>
      <div
        className={`sm:top-16 bottom-0 left-0 z-50 flex-col w-full px-4 py-2 min-product-info-plp`}
        id="product-sticky-bar"
      >
        <div className="block bg-white border border-gray-100 rounded shadow text-black pl-4 pr-5 pt-3">
          <div className="gap-4 px-0 flex mx-auto page-container product-sticky-swiper">
            <Swiper
              spaceBetween={5}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper swiper-h promotion-sticky"
            >
              {availablePromotions.length !== 0 ? (
                <>
                  {/* {applicablePromotions.map((datum: IPromotion) => (
                    <SwiperSlide
                      className="pb-[25px]"
                      key={`${datum.code}-available-promotion`}
                    >
                      <div
                        className="flex text-12 sm-text-12"
                        onClick={(event) => {
                          event.stopPropagation()
                          setPromotionData(datum)
                        }}
                      >
                        <div className="flex-none w-[10%]">
                          <div className="image-container">
                           {parseAdditionalInfo9(datum.additionalInfo9).img ? (
                              <img
                                src={`${NEXT_PUBLIC_IMAGE_BASE_URL}${parseAdditionalInfo9(datum.additionalInfo9).img}`}
                                alt={datum.code}
                              />
                            ) : (
                              <NewCouponIcon fill="#00890E" />
                            )}
                          </div>
                        </div>
                        <div className="flex-initial w-[60%]">
                          <div className="flex justify-start">
                            <p className="px-3 text-justify text-12 sm-text-12  text-[#00890E]">
                              {datum.croMessage !== ''
                                ? datum.croMessage
                                : datum.name}
                              <span className="text-[#EB5B25] ml-2">→</span>
                            </p>
                          </div>
                        </div>
                        <div className="flex-initial w-[30%]">
                          <button
                            className="flex w-full items-center justify-between h-[48px] w-[110px] px-[12px] py-[8px] font-medium text-white bg-black border-2 border-black rounded-sm shadow-sm hover:text-white hover:bg-gray-900 disabled:bg-opacity-50 disabled:border-opacity-5"
                            onClick={(event) => {
                              event.stopPropagation()
                              router.push('/cart')
                            }}
                          >
                            <div className="flex flex-col">
                              <img
                                alt="Add to bag"
                                src="https://dacdn2.damensch.com/damensch/icons/add-to-bag.svg"
                                width={30}
                                height={30}
                                className="relative right-0 inline-block pr-0"
                              />
                            </div>
                            <div>
                              <span className="text-md font-semibold">
                                {priceFormat(
                                  cartItems.grandTotal?.raw?.withTax
                                )}
                              </span>
                              <span className="block text-sm font-light">
                                {cartItems.lineItems.length} Items
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))} */}
                  {availablePromotions.map((datum: IPromotion) => (
                    <SwiperSlide
                      className="mb-[20px] "
                      key={`${datum.code}-available-promotion`}
                    >
                      <IntersectionObserverComponent
                        title=""
                        eventName={GA_EVENT.BOTTOM_NUDGE_IMPRESSION}
                        nudgeData={datum}
                      >
                        <div
                          className="flex text-12 sm-text-12"
                          onClick={(event) => {
                            event.stopPropagation()
                            setPromotionData(datum)
                            bottomNudgeClickEvent(datum)
                            setOpenDetailPopup(true)
                          }}
                        >
                          <div className="flex-none mr-2">
                            <div className="image-container">
                              {parseAdditionalInfo9(datum.additionalInfo9)
                                .img ? (
                                <img
                                  src={
                                    parseAdditionalInfo9(datum.additionalInfo9)
                                      .img
                                  }
                                  alt={datum.code}
                                  height="32px"
                                  width="24px"
                                />
                              ) : (
                                <NewCouponIcon width="24" height="24" />
                              )}
                            </div>
                          </div>
                          <div className="flex-initial">
                            <div className="flex  text-left">
                              <span className="font-normal text-12 sm-text-12 text-truncate-two-lines text-[#251000]">
                                {datum.croMessage !== ''
                                  ? datum.croMessage
                                  : datum.name}
                                <span className="text-[#EB5B25] ml-2">→</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </IntersectionObserverComponent>
                    </SwiperSlide>
                  ))}
                </>
              ) : (
                <div className="flex text-12 sm-text-12">
                  <div className="flex-none mr-2">
                    <div className="image-container">
                      <NewCouponIcon fill="#00890E" width="24" height="24" />
                    </div>
                  </div>
                  <div className="flex-initial mb-[10px]">
                    <div className="flex justify-start">
                      <div className="px-3 text-left text-12 sm-text-12 text-[#00890E]">
                        <p>You’ve unlocked all offers!</p>
                        <p>
                          {totalDiscount
                            ? `You’re saving ₹${totalDiscount}`
                            : 'Let’s proceed to checkout'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Swiper>
            <div>
              <button
                className="flex gap-2 items-center h-[48px] whitespace-nowrap min-w-[94px] px-[12px] py-[8px] font-medium text-white bg-black border-2 border-black rounded-sm shadow-sm hover:text-white hover:bg-gray-900 disabled:bg-opacity-50 disabled:border-opacity-5"
                onClick={(event) => {
                  event.stopPropagation()
                  localStorage.setItem('isFromCartIcon', 'bottom_cart_icon')
                  router.push('/cart')
                }}
              >
                <div className="flex flex-col">
                  <WhiteCartIcon />
                </div>
                <div>
                  <span className="text-xs font-semibold">
                    {priceFormat(cartItems.grandTotal?.raw?.withTax)}
                  </span>
                  <span className="block text-[10px] font-light">
                    {itemsInCart} {Number(itemsInCart) <= 1 ? 'ITEM' : 'ITEMS'}
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpenDetailPopup ? (
        <PromotionDetailPopup
          promotionData={promotionData}
          onClose={() => {
            setPromotionData(null)
            setOpenDetailPopup(false)
          }}
          deviceInfo={deviceInfo}
          previousPath={previousPath}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default React.memo(ProductListStickyBar)
