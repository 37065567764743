import { FC, useEffect, useState } from 'react'
import Router, { useRouter } from 'next/router'
import axios from '@services/axiosInterceptorInstance'
import Cookies from 'js-cookie'
import {
  collectionSlug,
  NEXT_GET_ALT_RELATED_PRODUCTS,
  API_STATUS,
  NEXT_GET_PAGE_PREVIEW_CONTENT,
  APP_VERSION,
  EmptyGuid,
  NEXT_BASKET_VALIDATE,
  NEXT_BULK_ADD_TO_CART,
  NEXT_GET_BASKET_PROMOS,
  NEXT_GET_ORDER_RELATED_PRODUCTS,
} from '@components/utils/constants'
import { IDeviceInfo, useUI } from '@components/ui/context'
import useCart from '@components/services/cart'
import { setItem } from '@components/utils/localStorage'
import useDataSubmit from '@commerce/utils/use-data-submit'
import { matchStrings } from '@framework/utils/parse-util'
import { EVENTS_MAP } from '@components/services/analytics/constants'
import eventDispatcher from '@components/services/analytics/eventDispatcher'
import { INSUFFICIENT_STOCK } from '@components/utils/textVariables'
import {
  errorSubmitData,
  getCurrentPage,
  getDefaultPaymentMethod,
  getSizeChartData,
  isValidJSON,
  resetSubmitData,
  isCartAssociated,
} from '@framework/utils/app-util'
import { listJusPayCards } from '@framework/utils/juspay-util'
import { recordGA4Event } from '@components/services/analytics/ga4'
import {
  LocalStorage,
  PaymentMethodType,
} from '@components/utils/payment-constants'
import { IUPIMethod } from '@components/checkout/CheckoutPayment/UPI/UPIMethod'
import {
  getSessionItem,
  setSessionItem,
} from '@components/utils/sessionStorage'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import { useGoogleAnalyticsEvent } from 'hooks/useGAEvent'
import { bindProductSizes, buttonTitle, handleClose } from './helper'
import CartSidebarViewIndex from './CartSidebarViewIndex'
import { fetchSavedUPIs } from '@components/MobileCart/helper'
import cartHandler from '@components/services/cart'
import { BasketServices } from 'services/basket/index.service'
import { addToCartGAEvent } from './helper'

declare const window: any

interface ICartSidebarView {
  config: any
  deviceInfo: IDeviceInfo
  previousPath: string
}

type ObjectBasketPromos = {
  applicablePromotions: Array<any>
  availablePromotions: Array<any>
}

const CartSidebarView: FC<any> = (props: ICartSidebarView) => {
  const { config, deviceInfo, previousPath } = props

  const {
    setAlertWithLessTimer,
    setAlert,
    loaderState,
    setCartItems,
    cartItems,
    basketId,
    isGuestUser,
    user,
    setAppVersionData,
  } = useUI()
  const trackMoEngageEvent = useMoEngageEvent()
  const trackGoogleAnalyticsEvent = useGoogleAnalyticsEvent()
  const router = useRouter()
  const isChannelStore = router?.query?.channelStore ? true : false
  const [isRemoveProdConfirmationOpen, setIsRemoveProdConfirmationOpen] =
    useState(false)
  const [isSizeDialogOpen, setIsSizeDialogOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<any>(undefined)
  const [productSizes, setProductSizes] = useState<Array<any> | undefined>(
    undefined
  )
  const [variantProducts, setVariantProducts] = useState<
    Array<any> | undefined
  >(undefined)
  const { getCart } = useCart()
  const { BasketViewed } = EVENTS_MAP.EVENT_TYPES
  const { Basket } = EVENTS_MAP.ENTITY_TYPES
  const { state: submitState, dispatch: submitDispatch } = useDataSubmit()
  const [basketPromos, setBasketPromos] = useState<
    ObjectBasketPromos | undefined
  >({
    applicablePromotions: [],
    availablePromotions: [],
  })
  const [relatedProducts, setRelatedProducts] = useState<any>()
  const [altRelatedProducts, setAltRelatedProducts] = useState<any>()
  const [reValidateData, setBasketReValidate] = useState<any>([])
  const [removedProduct, setRemovedProduct] = useState<any | Array<any>>()
  const [savedCards, setSavedCards] = useState<Array<any> | undefined>(
    undefined
  )
  const [savedUPIs, setSavedUPIs] = useState<Array<IUPIMethod> | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState({ action: '', state: false })
  const [preferredPaymentMethod, setPreferredPaymentMethod] =
    useState<any>(undefined)
  const [isBasketFetched, setIsBasketFetched] = useState(false)
  const [currentVariantData, setCurrentVariantData] = useState<any>('')
  const [wishlistLoader, setWishlistLoader] = useState(true)
  const [previousVariantData, setPreviousVarientData] = useState('')
  const [isGetBasketPromoRunning, setIsGetBasketPromoRunning] = useState(false)
  const [SizeChart, setSizeChartData] = useState({
    Sizes: [],
    Images: [],
    attribute: [],
    SizesToDisplay: [],
    SizeKeyToDisplay: [],
  })
  const [isSizeChartEnable, setSizeChartEnable] = useState(
    API_STATUS.NOT_STARTED
  )
  const [sizeDialogState, setSizeDialogState] = useState<any>({
    type: '',
  })
  const [convenienceData, setConvenienceData] = useState({
    title: undefined,
    description: undefined,
    softAddedProduct: undefined,
  })
  const sessionStoredUser = JSON.parse(getSessionItem('isUserAlreadyExist'))

  const isUserLoggedIn =
    !isGuestUser && user?.userId && user?.userId !== EmptyGuid

  const { asPath } = useRouter()
  let currentPage = getCurrentPage() || ''

  const getAppVersion = async () => {
    try {
      const { data }: any = await axios.get(NEXT_GET_PAGE_PREVIEW_CONTENT, {
        params: {
          slug: APP_VERSION,
          workingVersion: process.env.NODE_ENV === 'production' ? true : true, // TRUE for preview, FALSE for prod.
          channel: 'Web',
        },
      })
      setAppVersionData(data)
      setConvenienceData({
        title: data?.additionalchargetitle,
        description: data?.additionalchargedescription,
        softAddedProduct: data?.softaddedproduct,
      })
    } catch (error) {
      console.error(':: error ', error)
    }
  }

  useEffect(() => {
    if (selectedProduct) {
      setSizeChartEnable(API_STATUS.LOADING)
      const get = async () => {
        const data: any = await getSizeChartData(
          selectedProduct?.categoryItems?.[0]?.categoryName
        )
        const type = typeof data
        const _data = type === 'string' ? JSON.parse(data) : data
        if (_data) {
          let sizesToDisplay: Array<string> = []
          let sizesKeyToDisplay: Array<string> = []
          _data?.Sizes?.forEach((item: any, id: any) => {
            if (id === 0) {
              const fullKeys = Object.keys(item)
              const d = fullKeys?.filter(
                (i: any) => i !== 'Size' && i !== 'CategoryName'
              )
              const _d = d?.map((_i: any) => {
                const size = _i?.replace(/(Inches|Cms)$/, '')
                sizesKeyToDisplay?.push(size)
                return size?.replace(/([a-z])([A-Z])/g, '$1 $2')
              })
              const uniqueSizesKeyToDisplay = [...new Set(sizesKeyToDisplay)]
              const uniqueSizesToDisplay = [...new Set(_d)]
              sizesToDisplay = uniqueSizesToDisplay
              sizesKeyToDisplay = uniqueSizesKeyToDisplay
            }
          })
          setSizeChartData({
            ..._data,
            SizesToDisplay: sizesToDisplay,
            SizeKeyToDisplay: sizesKeyToDisplay,
          })
          setSizeChartEnable(API_STATUS.SUCCESS)
        } else {
          setSizeChartEnable(API_STATUS.FAILURE)
        }
      }
      get()
    }
  }, [selectedProduct])

  let firstProductId = ''
  if (cartItems?.lineItems?.length > 0) {
    firstProductId = cartItems?.lineItems?.length
      ? cartItems?.lineItems?.filter((x: any, idx: number) => idx == 0)[0]
          ?.productId
      : ''
  }

  const getUserId = () => {
    return user?.userId && user?.userId != EmptyGuid
      ? user?.userId
      : cartItems?.userId
  }

  const showRemove = (_product: Array<any> | any) => {
    setRemovedProduct(_product)
  }

  const handleUpdateProductItemSize = async (
    selectedProduct: any,
    newStockCode: string
  ) => {
    setIsLoading({ action: 'size-update', state: true })
    const oldLineItem = cartItems?.lineItems?.find((x: any) =>
      matchStrings(x?.productId, selectedProduct?.productId, true)
    )
    if (oldLineItem) {
      const oldProduct = {
        stockCode: selectedProduct.stockCode,
        parentProductId: null,
        qty: 0,
        basketId: '',
      }
      let replacementEligibilityDays: Number | null = null
      let returnEligibilityDays: Number | null = null
      const replacementObj = isValidJSON(selectedProduct?.customInfo4)
        ? JSON.parse(selectedProduct?.customInfo4)
        : {}

      // Access the value of "Replacement Eligibility"
      replacementEligibilityDays =
        replacementObj?.formatted?.data?.['Replacement Eligibility']

      const returnObj = isValidJSON(selectedProduct?.customInfo5)
        ? JSON.parse(selectedProduct?.customInfo5)
        : {}

      // Access the value of "Return Eligibility"
      returnEligibilityDays = returnObj?.formatted?.data?.['Return Eligibility']

      const newProduct = {
        stockCode: newStockCode,
        parentProductId: null,
        qty: oldLineItem?.qty,
        basketId: '',
        customInfo1: selectedProduct?.customInfo1,
        customInfo2: selectedProduct?.customInfo2,
        customInfo3: selectedProduct?.customInfo3,
        CustomInfo4: selectedProduct?.customInfo4,
        CustomInfo5: selectedProduct?.customInfo5,
        CustomInfo4Formatted: replacementEligibilityDays?.toString() || '',
        CustomInfo5Formatted: returnEligibilityDays?.toString() || '',
      }

      const updatedProducts = [...[newProduct], ...[oldProduct]]
      // const { data: newCart }: any = await axios.post(NEXT_BULK_ADD_TO_CART, {
      //   basketId,
      //   products: updatedProducts,
      // })
      const { data: newCart }: any = await BasketServices.bulkUpdateBasket({
        id: basketId,
        basketLine: updatedProducts,
      })

      if (newCart?.id && newCart?.id != EmptyGuid) {
        setCartItems(newCart)
        await fetchBasketReValidate()
        setSelectedProduct(undefined)
        setProductSizes(undefined)
        setVariantProducts(undefined)
        handleCloseSizeModal(false)
        resetSubmitData(submitDispatch)
        getBasketPromos(basketId)
      } else {
        setAlert({ type: 'error', msg: INSUFFICIENT_STOCK })
        handleCloseSizeModal(false)
        setSelectedProduct(undefined)
        setProductSizes(undefined)
        setVariantProducts(undefined)
        resetSubmitData(submitDispatch)
        errorSubmitData(submitDispatch, { error_code: 'GENERIC_ERROR' })
        getBasketPromos(basketId)
      }
    }
  }

  const getBasketPromos = async (basketId: string) => {
    const { data: basketPromos } = await axios.get(NEXT_GET_BASKET_PROMOS, {
      params: { basketId: basketId },
    })
    setBasketPromos(basketPromos)
    return basketPromos
  }

  const handleSoftAddProduct = async (productDetails: any) => {
    let replacementEligibilityDays: Number | null = null
    let returnEligibilityDays: Number | null = null
    const replacementEligeble = productDetails?.customAttributes
      ?.find((x: any) => x.key == 'product.replacement')
      ?.value?.toLowerCase()

    const replacementEligibility =
      replacementEligeble === 'true' &&
      productDetails?.customAttributes?.find(
        (x: any) => x.key == 'product.replacementeligibility'
      )?.value

    const replacementEligibilityMatch = JSON.stringify(
      replacementEligibility
    )?.match(/\d+/)

    if (replacementEligibilityMatch) {
      const number = parseInt(replacementEligibilityMatch[0], 10)
      replacementEligibilityDays = number
    }
    const returnObj = isValidJSON(productDetails?.customInfo5)
      ? JSON.parse(productDetails?.customInfo5)
      : {}

    // Access the value of "Return Eligibility"
    returnEligibilityDays = returnObj?.formatted?.data?.['Return Eligibility']
    const item = await cartHandler().addToCart(
      {
        basketId: basketId,
        productId: productDetails?.productId ?? productDetails?.recordId,
        qty: 1,
        // manualUnitPrice: productDetails?.price.raw.withTax,
        stockCode: productDetails?.stockCode,
        userId: user.userId,
        isAssociated: isCartAssociated(cartItems), //user.isAssociated,
        isChannelStore: isChannelStore ? true : false,
        CustomInfo4: JSON.stringify({
          formatted: {
            title: 'Replacement Eligibility',
            data: {
              'Replacement Eligibility': replacementEligibilityDays || null,
            },
          },
        }),
        CustomInfo5: JSON.stringify({
          formatted: {
            title: 'Return Eligibility',
            data: {
              'Return Eligibility': returnEligibilityDays || null,
            },
          },
        }),
        CustomInfo4Formatted: replacementEligibilityDays?.toString() || '',
        CustomInfo5Formatted: returnEligibilityDays?.toString() || '',
      },
      'ADD',
      { product: productDetails },
      (item: any) => {
        setCartItems(item)
        addToCartGAEvent(trackGoogleAnalyticsEvent, user, productDetails, 'Bag')
        // setTimeout(() => handleCloseSizeModal(false), SHORT_CLOSE_TIMER)
        resetSubmitData(submitDispatch)
      }
    )
    setCartItems(item)
  }

  const fetchRelatedProducts = async (productId?: string) => {
    const { data: relatedProducts }: any = await axios.post(
      NEXT_GET_ORDER_RELATED_PRODUCTS,
      {
        id: productId ?? firstProductId,
      }
    )
    setRelatedProducts(relatedProducts)
  }

  const fetchSavedCards = async () => {
    const input = {
      customer_id: getUserId(),
    }
    const authToken =
      localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''
    const user = localStorage?.getItem('user')
    const userDetails = user ? JSON.parse(user) : {}
    const userId = userDetails?.userId
    const listCardsResult = await listJusPayCards(input, authToken, userId)

    if (listCardsResult?.hasError) {
      resetSubmitData(submitDispatch)
      errorSubmitData(submitDispatch, listCardsResult?.error)
    }
    return listCardsResult?.cards ?? []
  }

  const handleFetchUPIs = async (preferredPaymentMethod: any) => {
    if (
      !isGuestUser &&
      matchStrings(preferredPaymentMethod?.method, PaymentMethodType.UPI, true)
    ) {
      const savedUPIs = await fetchSavedUPIs(getUserId())
      setSavedUPIs(savedUPIs)
    } else {
      setSavedUPIs([])
    }
  }

  const handleFetchCards = async (preferredPaymentMethod: any) => {
    if (
      !isGuestUser &&
      matchStrings(preferredPaymentMethod?.method, PaymentMethodType.CARD, true)
    ) {
      const savedCards = await fetchSavedCards()
      setSavedCards(savedCards)
    } else {
      setSavedCards([])
    }
  }

  const handleCartItems = async () => {
    getAppVersion()
    const items: any = await getCart({ basketId })
    items?.promotionsApplied?.forEach((promo: any) => {
      if (promo?.autoApply && typeof window !== 'undefined') {
        recordGA4Event(window, 'select_promotion', {
          ecommerce: {
            items: items?.lineItems?.map((item: any, itemId: number) => ({
              item_name: item?.name,
              item_var_id: item?.stockCode,
              price: item?.price?.raw?.withTax,
              quantity: item?.qty,
              item_brand: item?.brand,
              item_id: item?.sku,
              item_size:
                item.attributesJson && JSON.parse(item.attributesJson).Size,
              item_variant: item?.colorName,
            })),
            promotion_name: promo?.name,
            promotion_promocode: promo?.promoCode,
            selected_coupon: 'Single',
            discount_applied: promo?.discountAmt?.raw?.withTax,
            loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
          },
        })
      }
    })
    if (items?.lineItems?.length > 0) {
      setCartItems(items)
    }
  }

  const handleCartItemsLoadAsync = async () => {
    const promises = new Array<Promise<any>>()

    promises.push(
      await new Promise<any>(async (resolve: any, rejec: any) => {
        await handleCartItems()
        resolve()
      })
    )

    Promise.all(promises).then(() => {
      setIsBasketFetched(true)
    })
  }

  const handleLoadAsync = async (preferredPaymentMethod: any) => {
    const promises = new Array<Promise<any>>()

    promises.push(
      await new Promise<any>(async (resolve: any, reject: any) => {
        await handleFetchUPIs(preferredPaymentMethod)
        resolve()
      })
    )

    promises.push(
      await new Promise<any>(async (resolve: any, reject: any) => {
        await handleFetchCards(preferredPaymentMethod)
        resolve()
      })
    )

    // promises.push(
    //   await new Promise<any>(async (resolve: any, rejec: any) => {
    //     if (cartItems?.lineItems?.length) {
    //       const lastItemProductId =
    //         cartItems?.lineItems[cartItems?.lineItems?.length - 1]?.productId
    //       await fetchRelatedProducts(lastItemProductId)
    //     }
    //     resolve()
    //   })
    // )

    // promises.push(
    //   await new Promise<any>(async (resolve: any, rejec: any) => {
    //     await fetchBasketReValidate()
    //     resolve()
    //   })
    // )

    promises.push(
      await new Promise<any>(async (resolve: any, rejec: any) => {
        await getAlltrelatedProducts()
        resolve()
      })
    )

    Promise.all(promises)
  }

  const getAlltrelatedProducts = async () => {
    const { data: altRelatedProducts }: any = await axios.post(
      NEXT_GET_ALT_RELATED_PRODUCTS,
      {
        slug: collectionSlug,
      }
    )
    setAltRelatedProducts(altRelatedProducts)
  }

  const fetchBasketReValidate = async () => {
    const { data: reValidate }: any = await axios.post(NEXT_BASKET_VALIDATE, {
      basketId: basketId,
    })
    setBasketReValidate(reValidate?.result)
    return reValidate?.result
  }

  useEffect(() => {
    Cookies.set(
      LocalStorage.Key.IS_OFFLINE_STORE,
      `${isChannelStore ? true : false}`
    )
    setItem(LocalStorage.Key.IS_OFFLINE_STORE, isChannelStore ? true : false)
    eventDispatcher(BasketViewed, {
      entity: JSON.stringify({
        id: basketId,
        grandTotal: cartItems.grandTotal?.raw.withTax,
        lineItems: cartItems.lineItems,
        promoCode: cartItems.promotionsApplied,
        shipCharge: cartItems.shippingCharge?.raw?.withTax,
        shipTax: cartItems.shippingCharge?.raw?.tax,
        taxPercent: cartItems.taxPercent,
        tax: cartItems.grandTotal?.raw?.tax,
      }),
      entityName: 'Cart',
      entityType: Basket,
      eventType: BasketViewed,
      promoCodes: cartItems.promotionsApplied,
    })
    handleCartItemsLoadAsync()
    if (isUserLoggedIn && !sessionStoredUser) {
      setSessionItem('isUserAlreadyExist', JSON.stringify(true))
    }
    setTimeout(() => {
      setWishlistLoader(false)
    }, 2000)
  }, [])

  useEffect(() => {
    if (isBasketFetched) {
      let preferredPaymentMethod: string | undefined = undefined
      const userId = getUserId()
      if (isGuestUser || (userId && matchStrings(userId, EmptyGuid, true))) {
        preferredPaymentMethod = ''
      } else {
        preferredPaymentMethod = getDefaultPaymentMethod()
      }
      setPreferredPaymentMethod(preferredPaymentMethod)
      handleLoadAsync(preferredPaymentMethod)
    }
  }, [isBasketFetched])

  //Get fresh coupons and clear coupons coming from deleted products
  useEffect(() => {
    const handleAsync = async () => {
      const promise = await new Promise<any>(
        async (resolve: any, reject: any) => {
          await getBasketPromos(basketId)
          // await fetchBasketReValidate()
          setIsGetBasketPromoRunning(!isGetBasketPromoRunning)

          if (cartItems?.lineItems?.length) {
            const lastItemProductId =
              cartItems?.lineItems[cartItems?.lineItems?.length - 1]?.productId
            await fetchRelatedProducts(lastItemProductId)
          }
          resolve()
        }
      )

      Promise.resolve(promise)
    }

    // [GS, 07-12-2022]: Idea is to disallow multiple get basket promos calls to instantiate in the same lifecycle event.
    if (!isGetBasketPromoRunning) {
      setIsGetBasketPromoRunning(true)
      handleAsync()
    }
  }, [basketId, cartItems])

  useEffect(() => {
    if (removedProduct?.length || removedProduct?.id) {
      setIsRemoveProdConfirmationOpen(true)
      const checkNotRepeated =
        window?.location?.href?.includes('#removemodalopen')
      if (!checkNotRepeated) {
        Router?.push(
          { pathname: `${window?.location?.href}#removemodalopen` },
          `${window?.location?.href}#removemodalopen`,
          { shallow: true, scroll: false }
        )
      }
    }
  }, [removedProduct])

  useEffect(() => {
    if (!isRemoveProdConfirmationOpen) {
      setRemovedProduct(undefined)
    }
  }, [isRemoveProdConfirmationOpen])

  useEffect(() => {
    if (!asPath?.includes('#modalOpen') && isSizeDialogOpen) {
      setIsSizeDialogOpen(false)
    }
    if (!asPath?.includes('#removemodalopen') && isRemoveProdConfirmationOpen) {
      setIsRemoveProdConfirmationOpen(false)
    }
  }, [asPath])

  const handleCloseSizeModal = (val: boolean) => {
    const newUrl = window?.location?.href?.replaceAll('#modalOpen', '')
    Router.replace(newUrl)
    Router.back()
    setIsSizeDialogOpen(val)
    setIsLoading({ action: '', state: false })
  }

  const handleCloseRemoveModal = (val: boolean) => {
    const newUrl = window?.location?.href?.replaceAll('#removemodalopen', '')
    Router.replace(newUrl)
    Router.back()
    setIsRemoveProdConfirmationOpen(val)
  }

  const buttonConfig = buttonTitle({
    cartItems,
    selectedProduct,
    variantProducts,
    currentVariantData,
    setAlert,
    submitDispatch,
    previousVariantData,
    handleCloseSizeModal,
    handleUpdateProductItemSize,
    sizeDialogState,
    basketId,
    user,
    setAlertWithLessTimer,
    setCartItems,
    getBasketPromos,
    trackGoogleAnalyticsEvent,
    trackMoEngageEvent,
  })

  const handleQuickAddToBag = async (product: any, type?: any) => {
    // async call
    await bindProductSizes(product, setVariantProducts, setProductSizes)
    // set states
    const checkNotRepeated = window?.location?.href?.includes('#modalOpen')
    if (!checkNotRepeated) {
      Router?.push(
        { pathname: `${window?.location?.href}#modalOpen` },
        `${window?.location?.href}#modalOpen`,
        { shallow: true, scroll: false }
      )
    }
    setIsSizeDialogOpen(true)
    setSelectedProduct(product)
    const attributeParsedJson = product?.attributes
    const _size = attributeParsedJson
      ?.find((datum: any) => datum.key === 'clothing.size')
      ?.value?.toUpperCase()
    if (product?.attributesJson) {
      setPreviousVarientData(
        JSON.parse(product?.attributesJson)?.Size?.toUpperCase()
      )
      setCurrentVariantData(
        JSON.parse(product?.attributesJson)?.Size?.toUpperCase()
      )
    } else {
      setPreviousVarientData(_size)
      setCurrentVariantData(_size)
    }
    setSizeDialogState((v: any) => ({
      ...v,
      type,
    }))
  }

  return (
    <>
      <CartSidebarViewIndex
        handleClose={handleClose}
        currentPage={currentPage}
        previousPath={previousPath}
        altRelatedProducts={altRelatedProducts}
        deviceInfo={deviceInfo}
        wishlistLoader={wishlistLoader}
        reValidateData={reValidateData}
        handleQuickAddToBag={handleQuickAddToBag}
        showRemove={showRemove}
        basketPromos={basketPromos}
        fetchRelatedProducts={fetchRelatedProducts}
        getBasketPromos={getBasketPromos}
        relatedProducts={relatedProducts}
        savedUPIs={savedUPIs}
        setBasketReValidate={setBasketReValidate}
        preferredPaymentMethod={preferredPaymentMethod}
        config={config}
        savedCards={savedCards}
        isUserLoggedIn={isUserLoggedIn}
        fetchBasketReValidate={fetchBasketReValidate}
        isRemoveProdConfirmationOpen={isRemoveProdConfirmationOpen}
        isLoading={isLoading}
        handleCloseRemoveModal={handleCloseRemoveModal}
        setIsLoading={setIsLoading}
        removedProduct={removedProduct}
        isSizeDialogOpen={isSizeDialogOpen}
        loaderState={loaderState}
        handleCloseSizeModal={handleCloseSizeModal}
        setSelectedProduct={setSelectedProduct}
        setProductSizes={setProductSizes}
        setVariantProducts={setVariantProducts}
        buttonConfig={buttonConfig}
        productSizes={productSizes}
        SizeChart={SizeChart}
        currentVariantData={currentVariantData}
        isSizeChartEnable={isSizeChartEnable}
        variantProducts={variantProducts}
        selectedProduct={selectedProduct}
        setCurrentVariantData={setCurrentVariantData}
        convenienceData={convenienceData}
        handleSoftAddProduct={handleSoftAddProduct}
      />
    </>
  )
}

export default CartSidebarView
